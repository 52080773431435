<template>

<div class="background">

  <div class="flex algin">

    <div id="app-title" class="app-title">iOS 开发证书购买
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>

<div class="center-container-cer-buy">
<el-form>
  <div class="cerTitle" style="margin-left: 10px;margin-right: 10px;">{{buy_cer_notice}}</div>
<br>
<br>
  <div v-for="item in this.options2" :key="item.id" >
    <a>{{item.label}}</a>
  </div>
  
  <div class="container-cer">

  
<el-button size="medium" type="danger" style="margin-top:10px" @click="successDelegate()">代理权益</el-button>
    <el-button type="primary" size="medium" @click="onShowBuy()">证书管理</el-button>
    <el-button type="success" size="medium" style="margin-top:10px" @click="jumpPrivate()">独立池管理</el-button>

<el-row>
</el-row>

<el-row class="mobile-style">
    <div v-if="showDiv" class="bg-purple-cer">
        <div class="content-cer">
            <div v-for="item in this.priceData" :key="item.id" style="margin-right: 10px;">
                <el-radio v-model="radio" :label="item.id">
                    {{ item.title }}
                    <div class="payValue-code">￥{{ item.price }}.00</div>
                </el-radio>
            </div>
        </div>
    </div>
</el-row>


        <el-input 
  type="textarea"
  :rows="3" style="width:60%;margin-top:40px" v-model="input_udid" @input="handleInputChange" placeholder="UDID【可以一次添加多个，一行一个】"></el-input>
        <el-input style="width:60%;margin-top:10px" v-model="note" placeholder="请输入备注（可留空）"></el-input>
      
        <div class="login-button">
        <el-button type="warning" size="medium" @click="reloadPage2()">获取 UDID
        </el-button>
        <el-button type="danger" size="medium" @click="onBuy()">购买证书
        </el-button>
      </div>
  </div>
</el-form>
</div>
<el-dialog class="toast_qrcode"
  title="请扫码二维码，使用 默认浏览器 打开网址，获取 UDID."
  :visible.sync="dialogVisible2"
  width="350px">
  <div id="qrcode" class="qrcode" ref="qrcodeContainer"></div> 
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible2=false">取 消</el-button>
    <el-button type="primary" @click="reloadPage">已获取，返回填写</el-button>
  </span>
</el-dialog>

<el-dialog class="toast_qrcode"
  title="请手动输入证书设备的 UDID"
  :visible.sync="dialogVisible1"
  width="350px">
  <el-input
  type="textarea"
  :rows="2"
  placeholder="请输入设备UDID （多个UDID，每行一个）"
  v-model="textarea">
</el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible1 = false">取 消</el-button>
    <el-button type="primary" @click="reloadPage3">确认添加</el-button>
  </span>
</el-dialog>


<el-dialog class="toast_qrcode"
  title="请选择添加 UDID 方式"
  :visible.sync="dialogVisible"
  width="350px">
  <el-button type="primary" @click="reloadPage1"> 手动添加 UDID</el-button>
    <el-button type="primary" @click="reloadPage2"> 扫码添加 UDID</el-button>
</el-dialog>

</div>
</template>

<style>


.active_package_cer{
  position:absolute;
  right: 30px;
  top: 123px; 
  width: 50px;
  height: 50px;
}


  .select-cer1{
    width:40%;
    margin-top: 30px;
  }

  .select-cer2{
    width:30%;
  }

  .cerTitle{
    top: 20px; 
    widows: 10%;
    position: relative;
    color: green;
  }

  .center-container-cer-buy{
    background-color: white;
    width:70%;
    min-width: 340px;
    height:600px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  } 
  .bg-purple-cer {
    height:100px;
    background: #dceff7;
    /* min-width: 210px; */
    margin-left: 20px;
    margin-right: 20px;
    /* width: 90%; */
  }
  @media screen and (max-width: 768px) {
    .mobile-style {
        display: flex;
        overflow-x: auto;
    }
}



.content-cer{
  display:flex;
  justify-content:center;
  align-items:center;
}
</style>

<script>

import {PostAppleDevice} from '@/api/admin/app_version'
import {upgradeRole} from '@/api/member'
import {getSelf} from '@/api/member'
import {Loading, Message} from 'element-ui'
import QRCode from 'qrcodejs2'
import {getSelfDevice} from '@/api/member'
import {getQrCode} from '@/api/download'
import {certificate} from '@/api/download'
import {getPrice} from '@/api/member'
import {addUdid} from '@/api/member'

export default {
  data() {
    return {
      textarea:"",
      name: "qrCode",
      email: '',
      password: '',
       dialogVisible: false,
       dialogVisible1: false,
       dialogVisible2: false,
       fits: ['fill'],
       url: '',
       ceroptions: [{
          cervalue: '1',
          label: '公共池'
        }, {
          cervalue: '2',
          label: '独立池'
        }],
       cervalue:'1',
       value: '',
       loading :null,
       loading_edit: true,
       userData : null,
       mobUrl:null,
       priceData:[],
       centerDialogVisible:true,
       options2: [],
       optionsData: [],
       value2: '',
       radio:"3",
       input_udid:'',
       is_buying : false,
       note:"",
       buy_cer_notice:"",
       showDiv: true, 
       isShowDelegate:true,
    }
  },

  methods: {
    reloadPage(){
      window.location.reload();
    },
    handleSelectChange(value) {
     
      if (value == '1'){
        this.showDiv = true;
      }else{
        this.showDiv = false;
      }

    },

    active_package(){
      this.$router.push({
	path: `/activityList`,
})

    },

    reloadPage1(){
      this.dialogVisible = false;
      this.dialogVisible1 = true;
    },

    checkCer(){
      window.open('https://check.dumpapp.com');
    },

    jumpM1(){
      this.$router.push({
          path: `/cerBuym1`,
        })
    },
   async handleInputChange(udid){

      var lines = udid.split('\n').map(line => line.trim())
        if (lines.length && lines.length == 1){
          if (udid.length  == 40 || udid.length == 25){
          var deviceData =  await PostAppleDevice([udid]);
       
          for (let i = 0; i < deviceData.data.length; i++) {
            var device = deviceData.data[i].devices[0].model;
            if (device == undefined || device == ""){
              device = deviceData.data[i].devices[0].deviceClass;
            }
            this.note = device;
          }
          }
        }

    },

    reloadPage2(){
      var tThis = this;
      setTimeout(function() {
          tThis.creatQrCode();
      }, 200);

      this.dialogVisible = false;
      if (this.isMobile()){
        this.onGetUdid();
        return;
      }else{
        this.dialogVisible2 = true;
      }
    },

    jumpM1(){
      this.$router.push({
          path: `/cerBuym1`,
        })
    },

    async reloadPage3(){
      var udids = this.textarea.replace(/ /g,'');
      var snsArr=udids.split(/[(\r\n)\r\n]+/);
      snsArr.forEach((item,index)=>{
            if(!item){
                snsArr.splice(index,1);
            }
      })

      var continueRun = true;
      for (var i=0; i<snsArr.length; i++){
        var udid = snsArr[i];
        var requestData = {
            udid: udid,
        }

        if (udid.length  == 40 || udid.length == 25){
          var data = await addUdid(requestData);
        }else{
          alert("udid 格式不对，请检查格式 : ", udid);
          continueRun = false;
          break;
        }
      }

      if(continueRun){
        this.dialogVisible1 = false;
        this.refreshData();
      }

    },

    clickqq () {
      alert('请加 qq 群: 763789550')
    },

    async onRegister() {
        this.$router.push({
          path: `/register`,
        })
    },

    successDelegate(){
      if(!this.isShowDelegate){
        window.open("https://dumpappdoc.feishu.cn/docx/PHbadYcJToYvLBxfshMcs0qznqd")
        return;
      }

    this.$confirm(
  '是否支付 <strong><font color="#FF0000">288币</font></strong>，成为尊享永久VIP代理？<br>代理享受，<strong><font color="#FF0000">证书低至1元</font></strong>。<br><a href="https://dumpappdoc.feishu.cn/docx/PHbadYcJToYvLBxfshMcs0qznqd" target="_blank" style="color: #3498db; text-decoration: underline;">点击查看，查看代理权益介绍</a>',
  '成为VIP代理',
  {
    confirmButtonText: "立即成为代理",
    cancelButtonText: '稍后决定',
    dangerouslyUseHTMLString: true,
    type: 'warning'
  }
).then(() => {
  this.buyDelegate();
}).catch(() => {
  // 用户点击了取消或关闭对话框
});
  },

    async buyDelegate() {
      var data = await upgradeRole();

      if (data.role == "agent"){
        alert("恭喜您，已经成为代理，享受超多优惠！");
        window.location.reload();
      }
    },



    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

    creatQrCode() {
      new QRCode(this.$refs.qrcodeContainer, {  
          text: 'https://www.dumpapp.com/view_udid?code=' + this.code,  
          width: 200,  
          height: 200,  
          colorDark: '#000000',  
          colorLight: '#ffffff',  
          correctLevel: QRCode.CorrectLevel.H  
      });
    },

    isMobile() {
      if ("ontouchstart" in document.documentElement) {
        return true;
      } else {
        return false;
      }

      
      return isMobile;
    },

    async jumpPrivate(){
      this.$router.push({
            path: `/cerPrivate`,
      })
    },

    async onBuy(){
        // 获取批量UDID
        var lines = this.input_udid.split('\n').map(line => line.trim())
        if (!lines.length){
            this.openMsg("UDID 为空，请先点击 - 添加UDID");
            return;
        }

        // 遍历检查格式
        for (var i=0;i<lines.length; i++){
          var udidStr = lines[i];
          if (udidStr.length  != 40 && udidStr.length != 25){
            alert("udid 格式不对，请检查格式 : " + udidStr);
            return;
          }
        }

        // 给出提示
          var udidsStr = lines.join('<br>');
          this.$confirm( udidsStr + " <br> 是否是为以上设备进行购买证书", '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          dangerouslyUseHTMLString : true
        }).then(() => {
          // 如果是私有池，直接去添加
          if (this.cervalue == '2'){
            this.buyCer();
            return;
          }

          // D币的判断逻辑
          var downloadCount = this.userData.pay_count;



          for (var i=0; i<this.priceData.length; i++){
            var res = this.priceData[i];
            if (res.id == this.radio){

              this.buyCer();
              break;
            }
          }
        }).catch(() => {
             
        });
    },

    convertDeviceName(devName){
        if (devName == "iPhone5,1")  return "iPhone 5";
        if (devName == "iPhone5,2")  return "iPhone 5";
        if (devName == "iPhone6,1")  return "iPhone 5s";
        if (devName == "iPhone6,2")  return "iPhone 5s";
        if (devName == "iPhone7,2")  return "iPhone 6";
        if (devName == "iPhone7,1")  return "iPhone 6 Plus";
        if (devName == "iPhone8,1")  return "iPhone 6s";
        if (devName == "iPhone8,2")  return "iPhone 6s Plus";
        if (devName == "iPhone8,4")  return "iPhone SE";
        if (devName == "iPhone9,1")  return "iPhone 7";
        if (devName == "iPhone9,3")  return "iPhone 7";
        if (devName == "iPhone9,2")  return "iPhone 7 Plus";
        if (devName == "iPhone9,4")  return "iPhone 7 Plus";
        if (devName == "iPhone10,1")  return "iPhone 8";
        if (devName == "iPhone10,4")  return "iPhone 8";
        if (devName == "iPhone10,2")  return "iPhone 8 Plus";
        if (devName == "iPhone10,5")  return "iPhone 8 Plus";
        if (devName == "iPhone10,3")  return "iPhone X";
        if (devName == "iPhone10,6")  return "iPhone X";
        if (devName == "iPhone11,2")  return "iPhone XS";
        if (devName == "iPhone11,4")  return "iPhone XS Max";
        if (devName == "iPhone11,6")  return "iPhone XS Max";
        if (devName == "iPhone11,8")  return "iPhone XR";
        if (devName == "iPhone12,1")  return "iPhone 11";
        if (devName == "iPhone12,3")  return "iPhone 11 Pro";
        if (devName == "iPhone12,5")  return "iPhone 11 Pro Max";
        if (devName == "iPhone12,8")  return "iPhone SE";
        if (devName == "iPhone13,1")  return "iPhone 12 Mini";
        if (devName == "iPhone13,2")  return "iPhone 12";
        if (devName == "iPhone13,3")  return "iPhone 12 Pro";
        if (devName == "iPhone13,4")  return "iPhone 12 Pro Max";
        if (devName == "iPhone14,5")  return "iPhone 13";
        if (devName == "iPhone14,2")  return "iPhone 13 Pro";
        if (devName == "iPhone14,3")  return "iPhone 13 Pro Max";
        if (devName == "iPhone14,4")  return "iPhone 13 Mini";


        if (devName == "iPhone14,6")  return "iPhone SE3";
        if (devName == "iPhone14,7")  return "iPhone 14";
        if (devName == "iPhone14,8")  return "iPhone 14 Plus";

        if (devName == "iPhone15,2")  return "iPhone 14 Pro";
        if (devName == "iPhone15,3")  return "iPhone 14 Pro Max";
    },

    routerPay(){
      this.$router.push({
            path: `/pay`,
      })
    },
    onShowBuy(){
       this.$router.push({
            path: `/cerPage`,
      })
    },  
      openPayMsg(vip_str) {
        var confirmStr = "确认";
        confirmStr =  '进行充值';
         this.$confirm( '<strong>'  + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          cancelButtonText: '取消',
          dangerouslyUseHTMLString : true
        }).then(() => {
         this.routerPay(); 
        }).catch(() => {        
        });
      },
  
     openAlertMsg(data, vip_str) {
        var confirmStr = "确认"; 
         this.$confirm(data  +  '<strong>' + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          cancelButtonText: '取消',
          dangerouslyUseHTMLString : true
        }).then(() => {
          this.buyCer();
        }).catch(() => {        
        });
      },

     openMsg(vip_str) {
        var confirmStr = "确认";
         this.$confirm('<strong>' + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          dangerouslyUseHTMLString : true
        }).then(() => {
        }).catch(() => {        
        });
      },


     async buyCer(){
       
        // 遍历出所有UDID
        var lines = this.input_udid.split('\n').map(line => line.trim())
        var allCount = 0;

        for (var i=0; i<lines.length; i++){
          var udid = lines[i];
          this.showLoading();

          var data = {
            udid: udid,
            note: this.note,
            type: parseInt(this.radio)
          }

          if (this.cervalue == '2'){
            data['type'] = 999;
          }

          // 请求证书
          var result = await certificate(data);

          // 取消loading
          this.loading.close();
          this.is_buying = false;
          

          // 判断回调
          if(result.error != null){
            if (result.error.code == 30003){
              this.openMsg(result.error.message);
            } 
            if (result.error.code == 30002){
              this.openPayMsg("当前拥有D币个数不足，请进行充值（充值活动优惠中）"); 
            }
          }else{
            allCount++;
            console.log("allCount + ", allCount);
            console.log("lines.length + ", lines.length);
            if (allCount == lines.length){
              this.$router.push({
                path: `/cerPage`,
              })
            }
          }
        }
      },

    showLoading(){
       this.loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    },

    handleClose(done){
      this.dialogVisible = false;
      this.refreshData();
    },

    async requestQrCode() {
      var data = await getQrCode();
      this.mobUrl = data.url; 
      var arr = this.mobUrl.split("?code=");
      this.code = arr[1];
    },

    async showQrCode(){

      const codeHtml = document.getElementById("qrcode");
      if  (codeHtml != null){
        codeHtml.innerHTML = "";
      }

      this.dialogVisible = true;
    },
    
    async onGetUdid(){
        if (navigator.userAgent.indexOf("MicroMessenger") !== -1) {
            alert("请点击右上角按钮，在弹出的菜单中，选择 在Safari(默认浏览器)中打开!");
            return false;
        }

      window.location.href = this.mobUrl;
      const iOSVersion = getiOSVersion();
      if (iOSVersion) {
        setTimeout(function() {
          alert('请手动进入手机，设置 -- 通用 -- VPN 与设备管理 --- 信任证书');
         }, 2300);
      } else {
        setTimeout(function() {
          alert('请手动进入手机，设置 -- 通用 -- VPN 与设备管理 -- Dumpapp --- 信任证书')
          window.location.href = 'https://www.dumpapp.com/TZ.mobileprovision' 
        }, 2300);
      }
    },
    
    getiOSVersion() {
      var userAgent = navigator.userAgent;
      return userAgent.includes('Version/17.') || userAgent.includes('Version/18.');
    },

    async setPrice(){
        var responseInfo = await getPrice();
        this.priceData = responseInfo.data;

      var newData = [];
      for (var i=0; i<this.priceData.length; i++){
        var newDic = {};
        var data = this.priceData[i];
        newDic["value2"] = (i + 1).toString();
        newDic["label"] = "《" + data.title + "》 - " + data.description;
        newData.push(newDic);
      }
      this.value2 = '1';
      this.options2 = newData;

    },

    async getMemberSelf(){
      // 拿到请求数据 json
      var userData = await getSelfDevice();

      if (userData == undefined){
         alert("请先进行登录");
         this.$router.push({
          path: `/login`,
         })
        return;
      }

      var newData = [];

      this.loading_edit = false
      if (userData.data == null){
        return;
      }

      for (var i=0; i<userData.data.length; i++){
        var newDic = {};
        var data = userData.data[i];
        newDic["value"] = i;
        var product = this.convertDeviceName(data.product);
        if (product == undefined){
          product = "";
          newDic["label"] = data.udid + product;
        }else{
          newDic["label"] = data.udid + " --- " + product;
        }

        newDic["udid"] = data.udid;
        newData.push(newDic);
      }

      if (userData.data.length){
        this.value = 0;
      }
      var data = await getSelf()
      this.userData = data;
      this.buy_cer_notice = data.pay_campaign.buy_cer_notice;

      if (data.role == "agent"){
        document.getElementById("app-title").innerHTML="欢迎尊贵的 VIP 代理 !"
        this.isShowDelegate = false;
      }else{
        this.isShowDelegate = true;
      }
    },

    refreshData(){
      this.requestQrCode();
      this.getMemberSelf();
    }



  },

  mounted() {
    this.refreshData();
    this.centerDialogVisible = true;
    this.setPrice();

  },

  watch: {
  },
  name: 'Login'
}
</script>
